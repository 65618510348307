import { outpostClientAPI } from "@dashboard/fetch/client";
import useSWR from "swr/immutable";

export type ISortBy = "updatedAt" | "createdAt" | "name";
export type ISortDirection = "asc" | "desc";
export type IStatusFilter = string;

type tableQuery<T> = {
  url: string;
  query: string;
  initialData: T;
  git?: boolean;
  hub?: boolean;
  sort: {
    by: ISortBy;
    direction: ISortDirection;
  };
  status?: string;
  withQuery?: boolean;
  revalidateOnMount?: boolean;
};

function generateQueryString(params: Record<string, any>, withQuery: boolean) {
  const firstKey = Object.keys(params)[0];

  if (Object.keys(params).length === 1) {
    if (withQuery) {
      return `&${firstKey}=${params[firstKey]}`;
    }
    return `?${firstKey}=${params[firstKey]}`;
  }

  let query = ``;
  if (withQuery) {
    query = `&`;
  } else {
    query = "?";
  }

  const secondToLast = {};
  Object.assign(secondToLast, params);

  // @ts-ignore
  delete secondToLast[firstKey];
  for (const key in params) {
    if (params[key].trim()) query += `${key}=${params[key]}&`;
  }

  return query.slice(0, -1);
}

export function useTableQuery<T>(params: tableQuery<T>) {
  const queryString = generateQueryString(
    {
      q: params.query,
      sb: params.sort.by,
      sd: params.sort.direction,
      ...(params.status && params.status !== "all"
        ? { status: params.status }
        : {}),
    },
    !!params.withQuery,
  );

  const url = `${params.url}${queryString}`;

  const { data, isLoading } = useSWR(
    url,
    () =>
      outpostClientAPI.get<T>({
        //  @ts-ignore
        path: (params.git ? "/git" : params.hub ? "/hub" : "") + url,
      }),
    {
      fallback: params.initialData as any,
      ...(params.revalidateOnMount ? { revalidateOnMount: true } : {}),
    },
  );

  return {
    data: data?.data,
    isLoading,
  };
}
