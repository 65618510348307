"use client";

import { ArrowRightLgFill } from "@jengaicons/react";
import { buttonVariants } from "@primitives/button";
import { Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import Image from "next/image";
import React from "react";

import { HeaderProps } from "..";
import { HeaderLinks } from "./header-links";
import { HeaderMobile } from "./mobile";

export function HeaderPublic(props: HeaderProps) {
  return (
    <Row className="justify-between">
      <Row className="items-center">
        <Link href="/">
          <Image
            alt="site-logo"
            width={113}
            height={25}
            className="dark:invert"
            src="/outpost-logo.svg"
          />
        </Link>
        <HeaderLinks standalone={props?.headerLinksStandalone ? true : false} />
      </Row>
      <AuthActions className="ml-auto" />
      <HeaderMobile />
    </Row>
  );
}

function AuthActions(props: { className?: string }) {
  return (
    <div className="hidden items-center gap-4 md:flex">
      <Link color="default" href="/signin" className="text-bodyLg font-medium">
        Log in
      </Link>
      <Link href="/signup" className={buttonVariants()}>
        <span>Sign up</span>
        <ArrowRightLgFill size="16" />
      </Link>
    </div>
  );
}
