import { cn } from "@/lib/utils";

export function Separator({
  direction = "vertical",
  className = "string",
}: {
  direction?: "vertical" | "horizontal";
  className?: string;
}) {
  return (
    <div
      className={cn(
        `bg-border block cursor-default select-none overflow-hidden text-[transparent] ${
          direction === "vertical" ? "h-full w-[1px]" : "h-[1px] w-full"
        } `,
        className,
      )}
    >
      I
    </div>
  );
}
