import { ArrowRightLg, List } from "@jengaicons/react";
import { buttonVariants } from "@primitives/button";
import { Column } from "@primitives/layout";
import { Link } from "@primitives/link";
import { Sheet, SheetContent, SheetTrigger } from "@primitives/sheet";
import React from "react";

import { HeaderLink } from "./header-links";

export function HeaderMobile() {
  return (
    <Sheet>
      <SheetTrigger className="ml-auto grid h-9 w-9 place-items-center md:hidden">
        <List size={16} />
      </SheetTrigger>
      <SheetContent
        overlayStyles="md:hidden"
        showClose={false}
        className="right-0 top-[calc(var(--site-header-height--mobile)+1px)] w-full p-0 sm:w-3/4 md:hidden"
        side="left"
      >
        <Column className="h-[calc(100vh-var(--site-header-height--mobile))]">
          <Column className="flex-1 px-4 pt-6">
            <HeaderLink href="#">Products</HeaderLink>
            <HeaderLink href="#">Developers</HeaderLink>
            <HeaderLink href="#">Company</HeaderLink>
            <HeaderLink href="#">Pricing</HeaderLink>
          </Column>
          <Column className="gap-4 px-4 pb-6">
            <Link
              color="default"
              href="#"
              className={buttonVariants({
                variant: "outline",
                fullWidth: true,
              })}
            >
              Log in
            </Link>
            <Link
              target="_blank"
              href=" https://forms.gle/PfhEjyKj1q6RopF89"
              className={buttonVariants({ fullWidth: true })}
            >
              <span>Sign up</span>
              <ArrowRightLg size="16" />
            </Link>
          </Column>
        </Column>
      </SheetContent>
    </Sheet>
  );
}
