"use client";

import {
  AppWindow,
  BookOpen,
  GithubLogo,
  House,
  List,
} from "@jengaicons/react";
import { Button } from "@primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@primitives/dropdown";
import { Separator } from "@primitives/generic";
import { Link } from "@primitives/link";

const sectionTwo = [
  { name: "Homepage", href: "/", icon: <House size={16} />, newTab: true },
  { name: "Docs", href: "/docs", icon: <BookOpen size={16} />, newTab: true },
  {
    name: "Blog",
    href: "/blog",
    icon: <AppWindow size={16} />,
    newTab: true,
  },
];

const sectionThree = [
  {
    name: "Github",
    href: "https://github.com/outpostHq",
    icon: <GithubLogo size={16} />,
    newTab: true,
  },
];

export function WebsiteMenu() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" icon={<List />} className="h-9 w-9" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" sideOffset={8} className="w-48 p-1">
        {sectionTwo.map((i) => (
          <DropdownMenuItem className="mx-0" key={i.name} asChild>
            <Link prefix={i.icon} href={i.href} target="_blank">
              {i.name}
            </Link>
          </DropdownMenuItem>
        ))}

        <Separator direction="horizontal" />

        {sectionThree.map((i) => (
          <DropdownMenuItem className="mx-0" key={i.name} asChild>
            <Link prefix={i.icon} href={i.href} target="_blank">
              {i.name}
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
