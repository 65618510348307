"use client";

import { cn } from "@/lib/utils";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  fixed?: boolean;
  standalone?: boolean;
};

export function HeaderShell(props: Props) {
  const { fixed, standalone } = props;

  return (
    <header
      className={cn(
        "bg-default z-[100] flex h-[60px] items-center px-4 sm:px-6",
        fixed && "fixed inset-x-0 top-0",
        standalone && "border-b",
      )}
    >
      <div className="mx-auto w-full max-w-[var(--max-width)]">
        {props.children}
      </div>
    </header>
  );
}
