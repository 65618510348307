"use client";

import { Link } from "@primitives/link";
import { usePathname } from "next/navigation";
import { ReactNode, useMemo } from "react";

import { HeaderBreadcrumbs } from "../shared/breadcrumbs";

export function HeaderLinks(props: { standalone?: boolean }) {
  const pathname = usePathname() || "";
  const shouldShow = useMemo(
    () => (pathname?.split("/")?.length < 2 ? true : false),
    [pathname],
  );

  if (props?.standalone) {
    return <HeaderLinksStandalone />;
  }

  if (shouldShow) {
    return (
      <div className="ml-14 hidden items-center gap-6 md:flex">
        <HeaderLink href="#">Products</HeaderLink>
        <HeaderLink href="#">Developers</HeaderLink>
        <HeaderLink href="#">Company</HeaderLink>
        <HeaderLink href="#">Pricing</HeaderLink>
      </div>
    );
  } else {
    return <HeaderBreadcrumbs className="ml-4" />;
  }
}

export function HeaderLink(props: { children: ReactNode; href: string }) {
  return (
    <Link
      href={props.href}
      className="text-bodyLg leading-bodyLg text-soft pointer-events-none px-4 py-2 font-medium md:p-0"
    >
      {props.children}
    </Link>
  );
}
export function HeaderLinksStandalone() {
  return (
    <div className="ml-14 hidden items-center gap-6 md:flex">
      <HeaderLink href="#">Products</HeaderLink>
      <HeaderLink href="#">Developers</HeaderLink>
      <HeaderLink href="#">Company</HeaderLink>
      <HeaderLink href="#">Pricing</HeaderLink>
    </div>
  );
}
