import {
  BookBookmark,
  GearSix,
  Globe,
  HardDrives,
  House,
  Jobs,
  LockSimple,
  Service,
  TreeStructure,
} from "@jengaicons/react";
import { ReactNode } from "react";

export type ResourceData = {
  label: string;
  value: string;
  route: string;
  description: string;
  icon: ReactNode;
  new: {
    title: string;
    route: string;
    description: string;
  };
};

export type ResourceType =
  | "SERVICE"
  | "JOB"
  | "MACHINE"
  | "REPOSITORY"
  | "CONTROLLER";

const RESOURCE_TYPES: Record<ResourceType, ResourceType> = {
  SERVICE: "SERVICE",
  REPOSITORY: "REPOSITORY",
  MACHINE: "MACHINE",
  JOB: "JOB",
  CONTROLLER: "CONTROLLER",
};

const VISIBILITY = {
  private: {
    label: "Private",
    icon: <LockSimple size={16} />,
  },
  public: {
    label: "Public",
    icon: <Globe size={16} />,
  },
};

const SERVICE: ResourceData = {
  label: "Service",
  value: "services",
  route: "/(entityName)/services",
  description: "Deploy models/apps in production.",
  icon: <Service size={16} />,
  new: {
    title: "Create a new service",
    route: "/new/service",
    description:
      "To deploy a new Services, select template and import a pre-trained model.",
  },
};

const REPOSITORY: ResourceData = {
  label: "Repository",
  value: "repositories",
  route: "/(entityName)/repositories",
  description: "Host model or dataset for training.",
  icon: <BookBookmark size={16} />,
  new: {
    title: "Create a new repository",
    route: "/new/repository",
    description:
      "A repository contains all datasets & model files, along with their complete revision history.",
  },
};

const MACHINE: ResourceData = {
  label: "Machine",
  route: "/(entityName)/machines",
  value: "machines",
  description: "Cloud development environments.",
  icon: <HardDrives size={16} />,
  new: {
    title: "Create a new machine",
    route: "/new/machine",
    description: "Simplified cloud development environments.",
  },
};

const JOB: ResourceData = {
  label: "Jobs",
  value: "jobs",
  route: "/(entityName)/jobs",
  description: "Train, fine-tune or process data.",
  icon: <Jobs size={16} />,
  new: {
    title: "Create a new job",
    route: "/new/job",
    description: "Select a task and connect your dataset to train.",
  },
};

const CONTROLLER: ResourceData = {
  label: "Controller",
  value: "controllers",
  route: "/(entityName)/controllers",
  description: "Simplified load balancing.",
  icon: <TreeStructure size={16} />,
  new: {
    title: "Create a new controller",
    route: "/new/controller",
    description: "Simplified load balancing.",
  },
};

const SETTINGS = {
  label: "Settings",
  value: "settings",
  icon: <GearSix size={16} />,
};

const OVERVIEW = {
  label: "Overview",
  value: "overview",
  icon: <House size={16} />,
};

export {
  SERVICE,
  REPOSITORY,
  MACHINE,
  JOB,
  CONTROLLER,
  RESOURCE_TYPES,
  SETTINGS,
  OVERVIEW,
  VISIBILITY,
};
