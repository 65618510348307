"use client";

import { cn } from "@/lib/utils";
import { useTableQuery } from "@/src/hooks/use-table-query";
import { EntityParams } from "@dashboard/entity/types";
import { RTeamsList } from "@dashboard/user/types";
import { ChevronUpDown, Plus } from "@jengaicons/react";
import { Avatar, AvatarFallback, AvatarImage } from "@primitives/avatar";
import { Button } from "@primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandLabel,
  CommandList,
} from "@primitives/command";
import { Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import { Popover, PopoverContent, PopoverTrigger } from "@primitives/popover";
import { useParams, useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

type Props = {
  userAvatar: string;
  userName: string;
  teams: RTeamsList;
};

export function UserTeamSwitcher(props: Props) {
  const params = useParams<EntityParams>();
  const router = useRouter();

  const teamsExist = props.teams && props.teams.length > 0;

  const findAvatar = useCallback(
    (name: string | undefined) => {
      if (!name) return "";

      if (name === props?.userName) return props?.userAvatar;

      if (teamsExist) {
        return props.teams.find((i) => i.name === name)?.avatarUrl || "";
      }

      return "";
    },
    [props, teamsExist],
  );

  const [selectedEntity, setSelectedEntity] = useState({
    name: params?.entityName || props?.userName,
    avatar: findAvatar(params?.entityName) || props?.userAvatar,
  });

  const [query, setQuery] = useState("");

  const { data } = useTableQuery<{ teams: RTeamsList }>({
    initialData: { teams: props?.teams || [] },
    query,
    url: `/users/${props?.userName}/teams`,
    sort: {
      by: "name",
      direction: "desc",
    },
  });

  const fetchedTeams = data?.teams || [];

  return (
    <Row className="items-center gap-2">
      <Row className="items-center gap-3">
        <Link
          className="gap-3 font-semibold"
          href={`/${selectedEntity.name || props?.userName}`}
          prefix={
            <Avatar className="h-5 w-5">
              <AvatarImage src={selectedEntity?.avatar || props?.userAvatar} />
              <AvatarFallback name={selectedEntity?.name} />
            </Avatar>
          }
        >
          {selectedEntity.name || props?.userName}
        </Link>
      </Row>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            icon={<ChevronUpDown />}
            variant="plain"
            className="data-[state=open]:bg-hovered active:bg-hovered h-7 w-5 focus:ring-0"
          />
        </PopoverTrigger>
        <PopoverContent
          sideOffset={8}
          align="end"
          alignOffset={-32}
          className="z-[10002] w-[200px] p-0"
        >
          <Command>
            <CommandInput
              placeholder="Find team..."
              onValueChange={(v) => {
                setQuery(v);
              }}
            />
            <CommandList>
              <CommandEmpty className="text-bodySm text-soft px-4 py-4">
                No teams found
              </CommandEmpty>
              <CommandGroup>
                <CommandLabel>Personal</CommandLabel>
                <MemberItem
                  selected={selectedEntity?.name === props?.userName}
                  setEntity={setSelectedEntity}
                  name={props?.userName || ""}
                  image={props?.userAvatar || ""}
                />
              </CommandGroup>

              {teamsExist && (
                <CommandGroup>
                  <CommandLabel>Teams</CommandLabel>
                  {fetchedTeams.slice(0, 5).map((i: RTeamsList[number]) => (
                    <MemberItem
                      image={i?.avatarUrl}
                      name={i?.name}
                      selected={i?.name == selectedEntity?.name}
                      setEntity={setSelectedEntity}
                    />
                  ))}

                  {fetchedTeams.length > 5 && (
                    <CommandItem
                      className="text-primary text-bodyMd hover:text-primary cursor-pointer"
                      onSelect={() => {
                        props?.userName && router.push(`/settings/teams`);
                      }}
                    >
                      Show more...
                    </CommandItem>
                  )}
                </CommandGroup>
              )}

              <CommandGroup className="border-t pt-1">
                <button
                  onClick={() => {
                    router.push(`/new/team`);
                  }}
                  className="text-primary hover:text-primary hover:bg-hovered flex w-full items-center gap-3 rounded-lg px-4 py-2"
                >
                  <Plus size={16} />
                  <span>New team</span>
                </button>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </Row>
  );
}

type MemberItemProps = {
  name: string;
  image: string;
  setEntity: Dispatch<SetStateAction<{ name: string; avatar: string }>>;
  selected: boolean;
};

function MemberItem(props: MemberItemProps) {
  const router = useRouter();

  return (
    <CommandItem
      disabled={false}
      key={props?.name}
      value={props?.name}
      onSelect={() => {
        router.push(`/${props.name}`);
        props.setEntity({ name: props?.name, avatar: props?.image });
      }}
      className={cn(
        `text-bodyMd bg-default hover:bg-hovered flex cursor-pointer items-center gap-3 rounded-lg [&:not(:first-of-type)]:mt-1`,
        props.selected ? "bg-active text-default" : "",
      )}
    >
      <Avatar className="h-4 w-4">
        <AvatarImage alt="" src={props?.image} />
        <AvatarFallback name={props.name} />
      </Avatar>
      <span>{props?.name}</span>
    </CommandItem>
  );
}
